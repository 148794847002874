import Image from "next/legacy/image";
import React from "react";
import { Video } from "../../VideoSection/types";
import { PlayIconSmall } from "../components/PlayIconSmall";
export function ThumbnailRow({
  video,
  onClick,
  ...props
}: {
  video: Video;
  onClick?: () => void;
}) {
  return <button {...props} onClick={onClick} aria-label={video.title} className="md:col-span-5 flex flex-row gap-x-5 items-center group cursor-pointer lg:h-auto min-h-[80px] text-start" data-sentry-component="ThumbnailRow" data-sentry-source-file="ThumbnailRow.tsx">
      <div className="h-full aspect-video relative rounded-md overflow-hidden">
        <Image className="object-cover" src={video.previewPoster} alt={video.alt} layout="fill" data-sentry-element="Image" data-sentry-source-file="ThumbnailRow.tsx" />
        <div className="absolute inset-0 flex  justify-center items-center">
          <PlayIconSmall className="opacity-0 group-hover:opacity-100 !w-6 transition-opacity" data-sentry-element="PlayIconSmall" data-sentry-source-file="ThumbnailRow.tsx" />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex-1 text-body-small lg:text-h4 line-clamp-3 mr-2 text-balance">
          {video.title}
        </div>
      </div>
    </button>;
}