import React, { useState } from "react";
import clsx from "clsx";
import { toFieldPath } from "@stackbit/annotations";
import ReactPlayer from "react-player/vimeo";
import { Video } from "./types";
interface Props {
  video: Video;
}
export const InlineVideoPlayer: React.FC<Props> = ({
  video
}) => {
  const [playing, setPlaying] = useState(false);
  const stopPlaying = () => {
    setPlaying(false);
  };
  return <div className="relative aspect-video overflow-hidden rounded-xl shadow" {...toFieldPath(".video")} data-chromatic="ignore" data-sentry-component="InlineVideoPlayer" data-sentry-source-file="InlineVideoPlayer.tsx">
      <button className={clsx("fixed inset-0 flex bg-plum transition-opacity duration-700 ", {
      "z-50 opacity-80": playing,
      "pointer-events-none z-20 opacity-0": !playing
    })} onClick={stopPlaying} />

      <div
    // 16:9 aspect ratio
    className={clsx("relative pt-[56.25%]", {
      "z-50": playing,
      "z-30": !playing
    })} data-chromatic="ignore">
        <ReactPlayer playing={playing} url={video.src} style={{
        position: "absolute",
        top: 0,
        left: 0
      }} width="100%" height="100%" controls loop={false} muted={false} pip={false} onPlay={() => setPlaying(true)} onClickPreview={() => setPlaying(true)} onPause={() => setPlaying(false)} onEnded={() => setPlaying(false)} config={{
        title: video.alt
      }} data-sentry-element="ReactPlayer" data-sentry-source-file="InlineVideoPlayer.tsx" />
      </div>
    </div>;
};