import React from "react";
import ReactMarkdown from "react-markdown";
import { PullQuote } from "../../Article/PullQuote";
import clsx from "clsx";
type Props = React.ComponentProps<typeof ReactMarkdown> & {
  linkVariant?: keyof typeof LinkVariants;
  withProse?: boolean;
  className?: string;
};
export const Markdown: React.FC<Props> = ({
  className,
  withProse = false,
  linkVariant: variant = "underline",
  ...props
}) => {
  return <div {...props} className={clsx(className, {
    "prose prose-harmony text-current": withProse
  })} data-sentry-component="Markdown" data-sentry-source-file="Markdown.tsx">
      <ReactMarkdown {...props} skipHtml linkTarget={props.linkTarget ?? "_blank"} components={{
      h1: "h2",
      // Disable h1's from markdown
      blockquote: props => <PullQuote fade={false} quote={extractTextFromChildren(props.children)} {...props} />,
      // The content of the link changes according to the text entered in Stackbit
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      a: props => <a className={LinkVariants[variant]} {...props} />,
      ...props.components
    }} data-sentry-element="ReactMarkdown" data-sentry-source-file="Markdown.tsx" />
    </div>;
};
const LinkVariants = {
  underline: "underline hover:no-underline",
  "no-underline": "hover:underline no-underline"
};
function extractTextFromChildren(children) {
  return React.Children.toArray(children).map(child => {
    if (React.isValidElement(child)) {
      // Recursively extract text from nested children
      return extractTextFromChildren(child.props.children);
    }
    return child;
  }).join("");
}