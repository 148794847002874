import React from "react";
import { Video } from "./types";
interface Props {
  video: Video;
}
export const VideoBackdrop = ({
  video
}: Props) => {
  const shouldShowGradient = !video.darkMode && !!video.title;
  return <>
      {shouldShowGradient && <div className="bg-gradient-to-t-half absolute inset-0 from-plum/50" />}
      <video className="flex-1" autoPlay muted playsInline loop poster={video.previewPoster}>
        <source src={video.previewVideo} type="video/mp4" />
      </video>
    </>;
};