import { toFieldPath } from "@stackbit/annotations";
import React, { useEffect, useState } from "react";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { VideoPlayer } from "../../VideoSection/VideoPlayer";
import { Video } from "../../VideoSection/types";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { TitleArea } from "../components/TitleArea";
import { ThumbnailRow } from "./ThumbnailRow";
import { ThumbnailLarge } from "./ThumbnailLarge";
interface VideoGalleryProps extends SharedSanitySectionProps {
  items: Video[];
}
const SectionVideoGallery: React.FC<VideoGalleryProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  title,
  items
}) => {
  const [videos, setVideos] = useState(items);
  const [playingVideo, setPlayingVideo] = useState(false);
  useEffect(() => {
    // To support quick editing in Stackbit
    setVideos(items);
  }, [items]);
  const onPressThumbnail = (index: number) => {
    setPlayingVideo(true);
    if (index === 0) {
      return;
    }
    setVideos(prev => {
      // put the selected index first and the currently playing video last
      const newVideos = [...prev];
      const selectedVideo = newVideos.splice(index, 1);
      const latestVideo = newVideos.splice(0, 1);
      newVideos.unshift(selectedVideo[0]);
      newVideos.push(latestVideo[0]);
      return newVideos;
    });
  };
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionVideoGallery" data-sentry-source-file="SectionVideoGallery.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="SectionVideoGallery.tsx">
        <TitleArea titleAlignment="left" preheader={title} preheaderPath="title" isEyebrow className="mb-6 lg:mb-10" data-sentry-element="TitleArea" data-sentry-source-file="SectionVideoGallery.tsx" />
        <div className="grid grid-flow-row-dense md:grid-cols-12 md:grid-rows-3 gap-x-10 gap-y-8">
          <div className="aspect-video row-span-3 md:col-span-7 relative group overflow-hidden" {...toFieldPath(".items[0]")}>
            {videos[0] && <VideoPlayer autoplay={playingVideo} key={videos[0].src} video={videos[0]} previewElement={<ThumbnailLarge video={videos[0]} />} />}
          </div>
          {videos.slice(1, 4).map((v, i) => <ThumbnailRow {...toFieldPath(`.items[${i + 1}]`)} key={v.src} video={v} onClick={() => onPressThumbnail(i + 1)}></ThumbnailRow>)}
        </div>
      </Container>
    </Section>;
};
export default SectionVideoGallery;