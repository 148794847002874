import React from "react";
import { Video } from "../../VideoSection/types";
import { Chevron } from "../components/Chevron";
import { PlayIconSmall } from "../components/PlayIconSmall";
export function ThumbnailLarge({
  video,
  onClick
}: {
  video: Video;
  onClick?: () => void;
}) {
  return <button onClick={onClick} aria-label={video.title} className="cursor-pointer text-start" key={video.src} data-sentry-component="ThumbnailLarge" data-sentry-source-file="ThumbnailLarge.tsx">
      <div className="absolute left-4 top-4 md:top-8 md:left-8 z-20">
        <PlayIconSmall data-sentry-element="PlayIconSmall" data-sentry-source-file="ThumbnailLarge.tsx" />
      </div>
      <div className="absolute bottom-4 left-4 right-4 md:right-8 md:bottom-4 md:left-8 self-end text-white transition-transform duration-500 group-hover:translate-y-0 lg:translate-y-4">
        <span className="text-h4 lg:text-h1 line-clamp-2">{video.title}</span>
        <div className="lg:text-body-standard text-body-small mt-1 flex flex-row items-center transition-opacity duration-500 group-hover:opacity-100 lg:opacity-0">
          Watch Video
          <Chevron data-sentry-element="Chevron" data-sentry-source-file="ThumbnailLarge.tsx" />
        </div>
      </div>
    </button>;
}