import React from "react";
import { VideoPlayerWithPreview } from "./VideoPlayerWithPreview";
import { InlineVideoPlayer } from "./InlineVideoPlayer";
import { Video } from "./types";
export interface Props {
  video?: Video;
  previewElement?: React.ReactElement;
  autoplay?: boolean;
}
export const VideoPlayer: React.FC<Props> = ({
  video,
  previewElement,
  autoplay
}) => {
  if (!video) return null;
  if (!!video.previewPoster && !!video.previewVideo) {
    return <VideoPlayerWithPreview previewElement={previewElement} autoplay={autoplay} video={{
      ...video,
      previewPoster: video.previewPoster,
      previewVideo: video.previewVideo
    }} />;
  } else {
    return <InlineVideoPlayer video={video} />;
  }
};