import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import React from "react";
import { FadeUp } from "../../Fader";
import { MarkdownString } from "../types";
import { Markdown } from "./Markdown";
type TitleTagType = "h1" | "h2";
export type TitleAlignment = "center" | "left";
interface Props {
  preheader?: string;
  title?: string;
  text?: MarkdownString;
  titlePath?: string;
  preheaderPath?: string;
  textFieldPath?: string;
  isEyebrow?: boolean;
  titleTag?: TitleTagType;
  titleAlignment?: TitleAlignment;
  className?: string;
  fade?: boolean;
}
export type SharedTitleAreaProps = Pick<Props, "title" | "preheader" | "text" | "isEyebrow" | "titleTag" | "titleAlignment">;
export const TitleArea: React.FC<Props> = ({
  className,
  preheader,
  title,
  text,
  isEyebrow = false,
  titleTag: TitleTag = "h2",
  titleAlignment = "center",
  fade = false,
  textFieldPath = "text",
  preheaderPath = "preheader",
  titlePath = "title"
}) => {
  if (!title && !text && !preheader) {
    return null;
  }
  const EyeBrowTag = preheader && isEyebrow && !title ? TitleTag : "span";
  return <div className={clsx("flex flex-col", {
    "items-center text-center": titleAlignment === "center",
    "items-start text-left": titleAlignment === "left"
  }, className)} data-sentry-component="TitleArea" data-sentry-source-file="TitleArea.tsx">
      <FadeUp cascade enabled={fade} data-sentry-element="FadeUp" data-sentry-source-file="TitleArea.tsx">
        {preheader ? <EyeBrowTag className={clsx("block text-current opacity-80 empty:mb-0 empty:hidden", {
        "text-body-label": isEyebrow,
        "text-body-standard": !isEyebrow,
        "mb-2": !!title
      })} {...toFieldPath(`.${preheaderPath}`)}>
            {preheader}
          </EyeBrowTag> : <div />}
        {title ? <TitleTag className={clsx("whitespace-pre-wrap empty:hidden", {
        "text-e4 md:text-e3": TitleTag === "h1",
        "text-e5 md:text-e4": TitleTag === "h2",
        "text-balance": titleAlignment === "center"
      })} {...toFieldPath(`.${titlePath}`)}>
            {title}
          </TitleTag> : <div />}
        {text ? <Markdown className="text-body-large mt-6 mb-0 max-w-2xl text-current empty:mt-0 empty:hidden" {...toFieldPath(`.${textFieldPath}`)} children={text} /> : <div />}
      </FadeUp>
    </div>;
};