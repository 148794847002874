import clsx from "clsx";
import React from "react";
export const PlayButton: React.FC<{
  darkMode?: boolean;
  className?: string;
}> = ({
  darkMode = false,
  className
}) => {
  return <svg className={clsx("w-[28px] scale-100 transition-transform group-hover:scale-110 md:w-10", className)} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="PlayButton" data-sentry-source-file="PlayButton.tsx">
      <rect width="56" height="56" rx="28" fill="#1E1928" className={clsx({
      "fill-beige-64": !darkMode,
      "fill-plum ": darkMode
    })} data-sentry-element="rect" data-sentry-source-file="PlayButton.tsx" />
      <path d="M37.7002 27.1429C38.3669 27.5278 38.3669 28.49 37.7002 28.8749L23.888 36.8494C23.2213 37.2343 22.388 36.7532 22.388 35.9834L22.388 20.0344C22.388 19.2646 23.2213 18.7835 23.888 19.1684L37.7002 27.1429Z" fill="#EDE6DD" className={clsx({
      "fill-beige-64 ": darkMode,
      "fill-plum ": !darkMode
    })} data-sentry-element="path" data-sentry-source-file="PlayButton.tsx" />
    </svg>;
};