import React from "react";
import clsx from "clsx";
import { FadeUp } from "../Fader";
import { ReactNode } from "react";
interface Props {
  caption?: ReactNode;
  quote: string;
  className?: string;
  captionClassName?: string;
  withPadding?: boolean;
  fade?: boolean;
}
export function PullQuote({
  fade = true,
  quote,
  caption,
  captionClassName,
  className
}: Props) {
  return <FadeUp enabled={fade} className="relative my-12" data-sentry-element="FadeUp" data-sentry-component="PullQuote" data-sentry-source-file="PullQuote.tsx">
      <div className={clsx("items-center ", className)}>
        <div className="mb-4 h-12 overflow-hidden font-empathySerif text-8xl antialiased">
          “
        </div>
        <p className="font-empathySerif text-2xl leading-snug antialiased md:text-3xl" style={{
        marginTop: 0,
        marginBottom: 0
      }}>
          {quote}
        </p>
        {caption && <p className={clsx("text-body-sm mt-6", captionClassName)}>
            {caption}
          </p>}
      </div>
    </FadeUp>;
}