import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player/vimeo";
import { PlayControls } from "./PlayControls";
import { Video } from "./types";
import { VideoBackdrop } from "./VideoBackdrop";
interface Props {
  video: Video & Required<Pick<Video, "previewPoster" | "previewVideo">>;
  lazy?: boolean;
  previewElement?: React.ReactElement;
  autoplay?: boolean;
}
export const VideoPlayerWithPreview: React.FC<Props> = ({
  video,
  lazy = true,
  previewElement,
  autoplay
}) => {
  const {
    ref,
    inView
  } = useInView({
    triggerOnce: true,
    rootMargin: "300px"
  });
  const shouldLoadVideo = !lazy || inView;
  return <div ref={ref} className="group relative aspect-video overflow-hidden rounded-xl shadow" {...toFieldPath(".video")} data-chromatic="ignore" data-sentry-component="VideoPlayerWithPreview" data-sentry-source-file="VideoPlayerWithPreview.tsx">
      {shouldLoadVideo && <ReactPlayer playing={true} url={video.src} playIcon={previewElement || <div className="absolute inset-0">
                <PlayControls title={video.title} darkMode={video.darkMode} />
              </div>} light={autoplay ? undefined : <VideoBackdrop video={video} />} width="100%" height="100%" controls loop={false} muted={false} pip={false} config={{
      title: video.alt
    }} />}
    </div>;
};